@import '../../styles/customMediaQueries.css';

.root {
}

.heroContainer {
  margin-top: 48px;
}

.sections {
  margin: 0;
}

.sectionContent {
  composes: marketplacePageWidth from global;

  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 72px;
  }

  @media (--viewportLarge) {
    margin-bottom: 96px;
  }
}

.sectionContentFirstChild {
  composes: marketplacePageWidth from global;

  margin-top: 48px;
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
    margin-bottom: 72px;
  }

  @media (--viewportLarge) {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}

.sectionFullContent {
  /* Style */
  background-color: var(--matterColorNegative);

  /* Padding */
  padding-top: 48px;
  padding-bottom: 48px;

  @media (--viewportMedium) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (--viewportLarge) {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.sectionSlider {
  @media (max-width: 1023px) {
    padding-left: 0;
    padding-right: 0;
  }
}
